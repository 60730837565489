<template>
  <div class="container_margin">
    <b-modal id="modal-center-attach-roles" centered hide-footer hide-header>
      <div class="row">
        <div class="col">
          <b>{{ "Attach Role" }} </b>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="row mt-2">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" width="10%"></th>
                    <th scope="col" width="40%">{{ "Role" }}</th>
                    <th scope="col" width="50%">{{ "Description" }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in roles_to_attach"
                    :key="'roles_to_attach' + index"
                  >
                    <td width="10%">
                      <input
                        class="checkbox custom-checkbox"
                        :value="item"
                        type="checkbox"
                        v-model="attach_roles"
                      />
                    </td>

                    <td width="40%">
                      {{ item.name }}
                    </td>
                    <td width="50%">
                      {{ item.description }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-5"></div>
        <div class="col-3 float-right">
          <button class="btn btn-primary-outlined w-100">
            {{ "Cancel" }}
          </button>
        </div>
        <div class="col-4 float-right">
          <button class="btn btn-primary w-100">{{ "Attach Roles" }}</button>
        </div>
      </div>
    </b-modal>
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-8 mb-1"></div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-1">
        <button class="btn btn-primary-outlined w-100 float-right">
          {{ "Cancel" }}
        </button>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 mb-1">
        <button class="btn btn-primary w-100 float-right">
          {{ "Update Changes" }}
        </button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-md-6 col-12">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">{{ "Employee Details" }}</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Name" }}</b>
            </div>
            <div class="col-8">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="name"
                type="text"
                id="name"
                name="name"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Email" }}</b>
            </div>
            <div class="col-8">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="email"
                type="email"
                id="name"
                name="name"
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Status" }}</b>
            </div>
            <div class="col-8">
              <select
                class="form-select w-100 rounded"
                id="inputGroupSelect04"
                aria-label="Example select with button addon"
                v-model="chosenStatus"
              >
                <option
                  :value="lan"
                  :key="index"
                  v-for="(stat, index) in Status"
                >
                  {{ stat }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">{{ "Change Password" }}</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Password" }}</b>
            </div>
            <div class="col-8">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="password"
                type="password"
                id="name"
                name="name"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <b>{{ "Confirm Password" }}</b>
            </div>
            <div class="col-8">
              <b-form-input
                autocorrect="off"
                autocomplete="off"
                v-model="confirm_password"
                type="password"
                id="name"
                name="name"
              />
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-7"></div>
            <div class=" col-12 col-md-12 col-lg-12 col-xl-5">
              <button class="btn btn-primary w-100 float-right">
                {{ "Update Password" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold">
                {{ "Role Details" }}
              </h5>
            </div>

            <div class="col-6 col-md-4 col-lg-4 col-xl-2">
              <button
                v-b-modal.modal-center-attach-roles
                class="float-right btn btn-primary-outlined w-100"
              >
                {{ "Add Roles" }}
              </button>
            </div>
          </div>

          <div class="row mt-2  table-card">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" width="40%">{{ "Role" }}</th>
                    <th scope="col" width="50%">{{ "Description" }}</th>
                    <th scope="col" width="10%">{{ "Action" }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in roles_have"
                    :key="'roles_have' + index"
                  >
                    <!--   <td class="py-4">
                    <p class="font-weight-bold text-center text-muted"> Start Your Case Search 

                    </p>
                </td> -->

                    <td width="40%">
                      {{ item.name }}
                    </td>
                    <td width="50%">
                      {{ item.description }}
                    </td>
                    <td width="10%">
                      <button
                        @click="editEmployee"
                        class="btn-primary py-1 px-2"
                      >
                        {{ "Remove" }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      roles_to_attach: [], // Array to hold roles to attach
      attach_roles: [], // Array to store selected roles
      name: "", // Employee name
      email: "", // Employee email
      chosenStatus: "", // Selected status
      Status: ["Active", "Inactive"], // Status options
      password: "", // Password input
      confirm_password: "", // Confirm password input
      roles_have: [], // Array to hold roles already assigned
    };
  },
  methods: {
    editEmployee() {
      // Method to handle removing employee roles
    },
    attachRoles() {
      // Method to handle attaching roles to the employee
    },
    cancelAttachRoles() {
      // Method to handle canceling the role attachment modal
    },
    updatePassword() {
      // Method to handle updating the employee's password
    },
    cancelUpdate() {
      // Method to handle canceling the updates
    },
  },
};
</script>
<style scoped>
.container_margin {
  padding: 2rem;
}
</style>